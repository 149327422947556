import { fetchWrapper } from "@/helpers";

const ruta = process.env.VUE_APP_ROOT_API;

export default class paqueteService {
  async getPaqueteProductoAll() {
    const paquetes = await fetchWrapper.get(`${ruta}/paquete_productos`);
    return paquetes;
  }

  async getPaqueteProductoById(datos) {
    const paquete = await fetchWrapper.post(`${ruta}/paquete_productos/buscar`, datos);
    return paquete;
  }

  async sendPaqueteProductoNew(datos) {
    const nuevopaquete = await fetchWrapper.post(`${ruta}/paquete_productos`, datos);
    return nuevopaquete;
  }

  async savePaqueteProductos(datos) { 
    const nuevopaquete = await fetchWrapper.post(`${ruta}/paquete_productos/agregar`, datos);
    return nuevopaquete;
  }

  async updatePaqueteProducto(datos) {
    const paqueteActualizado = await fetchWrapper.put(
      `${ruta}/paquete_productos/` + datos.id,
      datos
    );
    return paqueteActualizado;
  }

  async deletePaqueteProducto(id) {
    return await fetchWrapper.delete(`${ruta}/paquete_productos/${id}`);
  }

  async getProductosPaquete(datos) {
    const productos = await fetchWrapper.post(`${ruta}/paquete_productos/productos`, datos);
    return productos;
  }
}